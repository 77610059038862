import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    [value],
  );

  return debouncedValue;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useFunctionDebounce = (_function, wait) => {
  let timeout;

  return (..._arguments) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      _function(..._arguments);
    }, wait);
  };
};

export { useDebounce, useQuery, useFunctionDebounce };
