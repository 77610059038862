import { featureFlagNames } from 'common/constants/global';
import {
  JobDetail,
  JobList,
  JobTaskList,
  JobBoardDetail,
  JobBoardPushEdit,
  LogCreate,
  LogDetail,
  LogList,
  SubJobsEdit,
  UserDetail,
  UserEdit,
  UserList,
  BadgesDetail,
  BadgesList,
  CoinTaggerList,
  CoinTransactions,
  LeaderboardList,
  JobLevelReview,
  CashPrizeRequest,
  CashPrizeRequestDetail,
  InviteCodesList,
  InviteCodesCreate,
  RejectionReason,
  QaMechanismPage,
  CreateTaskQuestionnaire,
  PreTaskReview,
} from 'pages';
import { DeletionRequest } from 'pages/DeletionRequest';
import { AdminQcItemReview } from 'pages/Jobs/AdminQCReview';
import PreTaskCreate from 'pages/PreTaskScreening/PreTaskCreate';
import ReferralRecordList from 'pages/Referral/ReferralRecordList';
import UserTaskDetail from 'pages/Users/UserTaskDetail';

const AdminRoutes = [
  {
    name: 'Job List',
    component: JobList,
    exact: true,
    path: '/admin/job-list',
  },
  {
    name: 'Job Detail',
    component: JobDetail,
    exact: true,
    path: '/admin/job-detail/:id',
  },
  {
    name: 'Job Task List',
    component: JobTaskList,
    exact: true,
    path: '/admin/job-task-list/:id',
  },
  {
    name: 'Sub Job Edit',
    component: SubJobsEdit,
    exact: true,
    path: '/admin/sub-jobs-edit/:id',
  },
  {
    name: 'User List',
    component: UserList,
    exact: true,
    path: '/admin/user-list',
  },
  {
    name: 'User Detail',
    component: UserDetail,
    exact: true,
    path: '/admin/user-detail/:id',
  },
  {
    name: 'Task Detail',
    component: UserTaskDetail,
    exact: true,
    path: '/admin/user-detail/:id/task-detail/:taskId',
  },
  {
    name: 'User Edit',
    component: UserEdit,
    exact: true,
    path: '/admin/user-edit',
  },
  {
    name: 'Badges List',
    component: BadgesList,
    exact: true,
    path: '/admin/badges',
  },
  {
    name: 'Badges Detail',
    component: BadgesDetail,
    exact: true,
    path: '/admin/badge-detail/:id',
  },
  {
    name: 'Log List',
    component: LogList,
    exact: true,
    path: '/admin/log-list',
  },
  {
    name: 'Log Create',
    component: LogCreate,
    exact: true,
    path: '/admin/log-create',
  },
  {
    name: 'Log Detail',
    component: LogDetail,
    exact: true,
    path: '/admin/log-detail/:id',
  },
  {
    name: 'Coin Tagger List',
    component: CoinTaggerList,
    exact: true,
    path: '/admin/coin-tagger-list',
  },
  {
    name: 'Coin Transactions',
    component: CoinTransactions,
    path: '/admin/coin-transactions/:id',
  },
  {
    name: 'Cash Prize Requests',
    component: CashPrizeRequest,
    exact: true,
    path: '/admin/cash-prize-requests',
    featureFlagGuard: featureFlagNames.disableCashRedemption,
  },
  {
    name: 'Cash Prize Request Detail',
    component: CashPrizeRequestDetail,
    exact: true,
    path: '/admin/cash-prize-detail/:id',
  },
  {
    name: 'Job Board Detail',
    component: JobBoardDetail,
    path: '/admin/job-board-detail/:id',
  },
  {
    name: 'Job Board Edit',
    component: JobBoardPushEdit,
    path: '/admin/job-board-edit/:id',
  },
  {
    name: 'Job Board Push',
    component: JobBoardPushEdit,
    path: '/admin/job-board-push/:id',
  },
  {
    name: 'Leaderboard',
    component: LeaderboardList,
    path: '/admin/leaderboard',
  },
  {
    name: 'Tagging Review - Job Level',
    component: JobLevelReview,
    path: '/admin/job/:id/tagging-review',
  },
  {
    name: 'Configure Rejection Reason',
    component: RejectionReason,
    path: '/admin/job-detail/:id/rejection-reason',
  },
  {
    name: 'Invite Codes',
    component: InviteCodesList,
    path: '/admin/invite-codes/list',
  },
  {
    name: 'Create Invite Codes',
    component: InviteCodesCreate,
    path: '/admin/invite-codes/create',
  },
  {
    name: 'Referrals',
    component: ReferralRecordList,
    path: '/admin/referrals',
  },
  {
    name: 'Create New Pre-Task Screening',
    component: PreTaskCreate,
    exact: true,
    path: '/admin/pre-task-create/:id',
  },
  {
    name: 'Add New Pre-Task Screening Items',
    component: PreTaskCreate,
    exact: true,
    path: '/admin/pre-task-add-items/:id',
  },
  {
    name: 'Create New Pre-Task Screening',
    component: PreTaskReview,
    exact: true,
    path: '/admin/pre-task-create-step-2/:id',
  },
  {
    name: 'Review Pre Task Screening',
    component: PreTaskReview,
    exact: true,
    path: '/admin/pre-task-review/:id',
  },
  {
    name: 'Deletion Requests',
    component: DeletionRequest,
    path: '/admin/deletion-request-list',
  },
  {
    name: 'Qa Mechanism',
    component: QaMechanismPage,
    path: '/admin/qa-mechanism/:id',
  },
  {
    name: 'Create Task Questionnaire',
    component: CreateTaskQuestionnaire,
    path: '/admin/task-questionnaire/:id',
  },
  {
    name: 'Admin QC - Item Review',
    component: AdminQcItemReview,
    path: '/admin/qc-review/items/:id',
    sidebar: false,
  },
];

export default AdminRoutes;
