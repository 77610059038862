import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TcToastNew } from 'common/components';
import { removeToast } from 'store/actions';

const StyledToastList = styled.ul`
  top: 10px;
  position: fixed;
  right: 24px;
  min-width: 240px;
  z-index: 1;
`;

const TcToastsList = (props) => {
  const { toasts, removeToast, children } = props;
  return (
    <>
      <StyledToastList>
        {toasts.map((toast) => {
          const { id } = toast;
          return (
            <>
              <TcToastNew {...toast} key={id} onDismissClick={() => removeToast(id)} />
            </>
          );
        })}
      </StyledToastList>
      {children}
    </>
  );
};

TcToastsList.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeToast: PropTypes.func,
  children: PropTypes.node,
};

const mapStateToProperties = (state) => {
  return {
    toasts: state.toasts,
  };
};

export default connect(mapStateToProperties, { removeToast })(TcToastsList);
