// subjob constants
export const allJobs = 'all-jobs';
export const assigned = 'assigned';
export const ongoing = 'ongoing';
export const completed = 'completed';
export const partial = 'partial';
export const pendingResolve = 'pending-resolve';
export const archived = 'archived';

// user list constants
export const user = 'user';
export const client = 'client';
export const admin = 'admin';

// user task resolving constants
export const pending = 'pending';
export const unassigned = 'unassigned';
export const approved = 'approved';
export const rejected = 'rejected';
export const expired = 'expired';
export const unclaimed = 'unclaimed';
export const pipelineOngoing = 'pipelineOngoing';

export const editAccuracyCompletion = 'accuracy / % completed';
export const editCoins = 'coins';

// content flag
export const adult = 'adult';

// file upload types
export const srtStep3 = 'srt';
export const audioStep3 = 'audio';
export const sampleImages = 'sample-images';
export const configInputStep3 = 'config-input';
export const imageInputStep3 = 'image-input';

// toast constants
export const statusCardSuccess = 'success';
export const statusCardError = 'error';

// referral constants
export const referralReward = 'referralReward';
export const allReferrals = 'all';
export const pendingReferrals = 'pending';
export const completedReferrals = 'completed';

// job methods
export const JobMethod = {
  // Image
  ImageBoundingPolygon: 'image_bounding_polygon',
  ImageBoundingPolygonQcImageSwipeTwoWay: 'image_bounding_polygon_qc_image_swipe_two_way',
  ImageBoundingBox: 'image_bounding_box',
  ImageCollectionBasic: 'image_collection_basic',

  // Audio
  AudioTranscriptionCleansing: 'audio_transcription_cleansing',
  AudioCollection: 'audio_collection',
};

export const inputTypedJobMethods = [JobMethod.AudioCollection, JobMethod.ImageCollectionBasic];

export const aiAssistanceSupportedJobMethod = [JobMethod.ImageBoundingPolygon];

export const intervals = {
  fetchGenerationStatusData: 10 * 1000, // 10 seconds
};

export const CSVDownloadOptions = {
  CSV: 'csv',
  CSVWithBom: 'csv-bom',
};

// log constants
export const logTypes = {
  announcement: 'announcement',
  update: 'update',
  maintenance: 'maintenance',
  message: 'message',
  others: 'others',
};

// Feature flags
export const featureFlagNames = {
  disableCashRedemption: 'disable-cash-redemption',
};

export const adminItemReviewSupportedJobMethods = [JobMethod.ImageBoundingBox, JobMethod.ImageCollectionBasic];
