import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import FeatureFlagsUtils from 'utils/helper/featureFlagsUtils';

const AdminRoute = (props) => {
  const { path, isLogin, sidebar, setShowSidebar, featureFlagGuard } = props;

  if (sidebar === false) {
    setShowSidebar(false);
  } else {
    setShowSidebar(true);
  }

  if (featureFlagGuard) {
    const variant = FeatureFlagsUtils.getFlag(featureFlagGuard);

    if (variant !== 'off') {
      return <Redirect to="/" />;
    }
  }

  if (!isLogin) {
    return <Redirect to="/auth" />;
  } else if (isLogin && path === '/admin/*') {
    return <Redirect to="/admin/job-list" />;
  } else {
    return <Route {...props} />;
  }
};

AdminRoute.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired,
};

const mapStateToProperties = (state) => {
  return {
    isLogin: state.auth.isLogin,
  };
};

export default connect(mapStateToProperties)(AdminRoute);
