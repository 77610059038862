import { TextareaAutosize } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { TcIcon } from 'common/components';

const TcTextAreaClass = `
  tw-rounded-lg
  tw-py-2
  tw-px-4
  tw-border
`;

const TcTextareaWrapper = styled.div`
  display: inline-block;
  position: relative;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

const TcTextareaStyled = styled(TextareaAutosize)`
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  &:focus {
    online: none;
  }
`;

const TcIconFloatingClass = `
  tw-absolute
  tw-top-0
  tw-right-0
  tw-bottom-0
  tw-flex
  tw-items-center
  tw-justify-center
  tw-px-2
  tw-m-1
  tw-bg-white
`;

const TcTextarea = React.forwardRef((props, reference) => {
  const { icon, full, name, placeholder, onChange, value, className, rowsMin } = props;

  const RenderIcon = () => {
    if (!icon) return null;
    return (
      <div className={TcIconFloatingClass}>
        <TcIcon src={icon} />
      </div>
    );
  };

  return (
    <TcTextareaWrapper full={full}>
      <TcTextareaStyled
        className={TcTextAreaClass + className}
        ref={reference}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        full={full}
        rowsMin={rowsMin}
      />
      <RenderIcon />
    </TcTextareaWrapper>
  );
});

TcTextarea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rowsMin: PropTypes.string,
  full: PropTypes.bool,
  icon: PropTypes.object,
};
export default TcTextarea;
