import { isObject, isPlainObject, mapKeys, mapValues, camelCase, pickBy } from 'lodash-es';
import { localStorage, dayjs } from 'utils/libraries';

const HOURS_PER_DAY = 24;
const MINUTES_PER_DAY = 1440;
const MINUTES_PER_HOUR = 60;

const handleAsync = async (promise) => {
  try {
    const response = await promise;
    return [response, undefined];
  } catch (error) {
    return [undefined, error];
  }
};

const convertDate = (date) => {
  if (!date) return null;
  return dayjs(date).format('DD MMMM YYYY');
};

const checkToken = (date) => {
  return localStorage.getItem(process.env.REACT_APP_SESSION_NAME);
};

const setToken = (token = '') => {
  localStorage.setItem(process.env.REACT_APP_SESSION_NAME, token, {
    expires: Number.parseInt(process.env.REACT_APP_SESSION_EXPIRES, 10),
  });
};

const checkCredentials = () => {
  const credentials = JSON.parse(localStorage.getItem(process.env.REACT_APP_CREDENTIALS_NAME));
  return credentials || null;
};

const setCredentials = (credentials) => {
  localStorage.setItem(process.env.REACT_APP_CREDENTIALS_NAME, JSON.stringify(credentials), {
    expires: Number.parseInt(process.env.REACT_APP_SESSION_EXPIRES, 10),
  });
};

const destroy = () => {
  localStorage.clear();
};

const buildQueryParameters = (query) => {
  const cleanedQuery = pickBy(query, (value) => value != null);
  return Object.keys(cleanedQuery)
    .map((item) => {
      if (Array.isArray(query[item])) {
        return query[item].map((text) => buildQueryParameter(item, text)).join('&');
      }

      return buildQueryParameter(item, query[item]);
    })
    .join('&');
};

const buildQueryParameter = (key, value) => {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};

const handleBundleFiles = (files) => {
  let error = '';
  const bundleFiles = [];
  const bundleSizeLimit = Number(process.env.REACT_APP_UPLOAD_SIZE_LIMIT) || 50000000; // nginx limit 50 MB
  const bundleCountLimit = Number(process.env.REACT_APP_UPLOAD_COUNT_LIMIT) || 900; // backend limit max 1000 files
  let bundleFile = [];
  let bundleSize = 0;

  for (let index = 0; index < files.length; ++index) {
    if (bundleSize + files[index].size <= bundleSizeLimit && bundleFile.length < bundleCountLimit) {
      bundleFile.push(files[index]);
      bundleSize += files[index].size;
    } else if (bundleFile.length > 0) {
      bundleFiles.push(bundleFile);
      bundleFile = [];
      bundleSize = 0;
      --index;
    } else {
      error = `File size is greater than the ${bundleSizeLimit / 1000000} MB limit!`;
      break;
    }
  }
  // push the last bundle
  if (bundleFile.length > 0) {
    bundleFiles.push(bundleFile);
  }
  return error ? { error } : bundleFiles;
};

const toFixedNumber = (number, digits, base) => {
  var pow = Math.pow(base || 10, digits);
  return Math.round(number * pow) / pow;
};

const preventSubmitOnEnter = (event) => {
  if (event.key === 'Enter') event.preventDefault();
};

const transformApiErrors = (errors) => {
  const transformedError = {};
  errors.forEach((error) => {
    const key = error.property;
    const value = Object.values(error.constraints)[0];
    transformedError[key] = value;
  });
  return transformedError;
};

const minutesToFormattedTime = (mins = 0) => {
  const days = Math.floor(mins / MINUTES_PER_DAY);
  const hours = Math.floor((mins / MINUTES_PER_HOUR) % HOURS_PER_DAY);
  const minutes = Math.floor(mins % MINUTES_PER_HOUR);

  return { days, hours, minutes };
};

// Copied from Backend
const mapKeyDeep = (data, iteratee) => {
  if (Array.isArray(data)) {
    return data.map((o) => mapKeyDeep(o, iteratee));
  }

  if (isPlainObject(data)) {
    return mapValues(mapKeys(data, iteratee), (value) => {
      if (isObject(value)) {
        return mapKeyDeep(value, iteratee);
      }

      return value;
    });
  }

  return data;
};

const deepCamelCaseKeys = (data) => {
  if (!data) {
    return;
  }

  return mapKeyDeep(data, (_, k) => camelCase(k));
};

export {
  handleAsync,
  convertDate,
  checkToken,
  setToken,
  checkCredentials,
  setCredentials,
  destroy,
  buildQueryParameters as buildQueryParams,
  buildQueryParameter as buildQueryParam,
  handleBundleFiles,
  toFixedNumber,
  preventSubmitOnEnter,
  transformApiErrors,
  minutesToFormattedTime,
  deepCamelCaseKeys,
  MINUTES_PER_DAY,
};

export * from './canvas';
