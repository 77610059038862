import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { TcButton, TcLabel } from 'common/components';

const COMMON_UPLOAD_TYPES_MAP = {
  image: {
    accept: 'image/jpeg, image/png',
    title: 'Allowed extension PNG, JPEG, JPG',
  },
  text: {
    accept:
      '.csv, text/plain, application/vnd.ms-excel, text/x-csv, application/csv, application/x-csv, text/csv, text/comma-separated-values, text/x-comma-separated-values, text/tab-separated-values',
    title: 'Allowed extension CSV',
  },
};

const UPLOAD_TYPES_MAP = {
  image: COMMON_UPLOAD_TYPES_MAP.image,
  text: COMMON_UPLOAD_TYPES_MAP.text,
  audio: {
    accept: '.mp3, .wav',
    title: 'Allowed Extension .Wav or .Mp3 Max File 90mb',
  },
  srt: {
    accept: '.srt',
    title: 'Allowed Extension .Srt',
  },
  'config-input': COMMON_UPLOAD_TYPES_MAP.text,
  'image-input': COMMON_UPLOAD_TYPES_MAP.image,
  errorMaxFile: {
    title: 'The number of your files exceeds the limit',
  },
};

const TEXT_COLOR = {
  default: 'var(--color-black)',
  error: 'var(--color-red-600)',
};

const TcDropZoneStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px dashed #6c624f;
  border-radius: 6px;
  outline: none;
  transition: border 0.24s ease-in-out;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: var(--color-gray-400);
      & button {
        cursor: not-allowed;
      }
    `}
`;

const TcDropZone = (props) => {
  const { type, onDrop, disabled, onChange, additionalDescription, maxFiles } = props;

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: UPLOAD_TYPES_MAP[type]?.accept,
    onDrop,
    maxFiles: maxFiles || 0,
    disabled: disabled,
  });

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      onChange && onChange(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <TcDropZoneStyled {...getRootProps()} disabled={disabled}>
      <input {...getInputProps()} />
      <TcLabel color={TEXT_COLOR.default} className="tw-mb-6" title="Drag File Here to Upload" />
      <TcLabel color={TEXT_COLOR.default} className="tw-mb-6" title="Or" />
      <TcButton disabled={disabled} type="button" className="tw-mb-6" label="Browse Files" />
      <TcLabel
        color={fileRejections.length ? TEXT_COLOR.error : TEXT_COLOR.default}
        title={fileRejections.length ? UPLOAD_TYPES_MAP.errorMaxFile.title : UPLOAD_TYPES_MAP[type]?.title}
      />
      {additionalDescription && (
        <TcLabel color={fileRejections.length ? TEXT_COLOR.error : TEXT_COLOR.default} title={additionalDescription} />
      )}
    </TcDropZoneStyled>
  );
};

TcDropZone.propTypes = {
  type: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  additionalDescription: PropTypes.string,
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

TcDropZone.defaultTypes = {
  type: 'image',
};

export default TcDropZone;
