import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class AdminQcService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getAdminReviewItems(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);

      const url = `${endpoints.GET_ADMIN_REVIEW_ITEMS}?${queryParameters}`;

      const { data, status } = await this.HTTP.get(url);

      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }
}
